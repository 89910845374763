<template>
  <div>
    <base-material-card
      title="项目快速跟踪小组"
      icon="mdi-account-group-outline"
      class="mt-12 pr-0"
    >
      <v-col
        cols="12"
        class="line_box"
      >
        <v-card
          class="mx-auto pa-5 mt-2 "
          outlined
        >
          <v-row>
            <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                项目引荐人 ：
              </b>
              <p class="ma-0">
                {{ parentList.data.contact }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                部门及职务 ：
              </b>
              <p class="ma-0">
                {{ parentList.data.ProjectDepartmentInformation }}
              </p>
            </v-col>
            <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                联系方式 ：
              </b>
              <p class="ma-0">
                {{ parentList.data.phone }}
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-for="(item,inx) in desserts2"
          v-show="item.data.contacts"
          :key="inx"
          class="mx-auto pa-5 mt-2 "
          outlined
        >
          <v-row>
            <v-col
              cols="12"
              class="ma-0 pa-0 mb-2"
            >
              <p
                class="ma-0"
              >
                <b class="red--text text--darken-4">
                  推送市 ：
                </b>
                <span>{{ item.data.parks }}</span>
              </p>
            </v-col>
            <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                市项目联络人 ：
              </b>
              <p class="ma-0">
                {{ item.data.contacts }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                部门及职务 ：
              </b>
              <p class="ma-0">
                {{ item.data.job }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                联系方式 ：
              </b>
              <p class="ma-0">
                {{ item.data.tel }}
              </p>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          v-for="(item,inx) in desserts"
          :key="inx"
          class="mx-auto pa-5 mt-2 "
          outlined
        >
          <v-row>
            <v-col
              cols="12"
              class="ma-0 pa-0 mb-2"
            >
              <p
                class="ma-0"
              >
                <b class="red--text text--darken-4">
                  拟承接县市区 ：
                </b>
                <span>{{ item.name }}</span>
              </p>
            </v-col>
            <v-col
              cols="4"
              class="ma-0 pa-0 mb-1"
            >
              <b class="red--text text--darken-4">
                项目承接负责人 ：
              </b>
              <p class="ma-0">
                {{ item.data.contacts }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0 mb-1"
            >
              <b class="red--text text--darken-4">
                部门及职务 ：
              </b>
              <p class="ma-0">
                {{ item.data.Department }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0 mb-1"
            >
              <b class="red--text text--darken-4">
                联系方式 ：
              </b>
              <p class="ma-0">
                {{ item.data.tel }}
              </p>
            </v-col>
            <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                项目承办人 ：
              </b>
              <p class="ma-0">
                {{ item.data.undertaker }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                部门及职务 ：
              </b>
              <p class="ma-0">
                {{ item.data.positions }}
              </p>
            </v-col> <v-col
              cols="4"
              class="ma-0 pa-0"
            >
              <b class="red--text text--darken-4">
                联系方式 ：
              </b>
              <p class="ma-0">
                {{ item.data.information }}
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </base-material-card>
  </div>
</template>

<script>
  export default {
    components: {
      Upload: () => import('../component/Upload.vue'),
    },
    props: {
      formId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        imgIcon: 'md-document',
        parkCode: [],
        childformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        desserts: [],
        desserts2: [],
        parentList: {},
        createData: '',
        parks: '',
        searchParame: {
          page: 0,
          size: 5,
          formId: '7354c8f1-3cfb-463a-b0a4-e13623c0051b',
        },
        name: '',
        imageShow: false,
        nowPage: 1,
        pageNum: 0,
        orgQX: false,
        openItem: {},
        contacts: '',
        tel: '',
        job: '',
        underTake: '',
        pushOrder: [],
        upType: ['pdf', 'doc', 'docx'],
      }
    },
    async mounted () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      if (this.orgQX) {
        await this.tsDetail()
      }
      await this.getDetail()
      await this.getchild()
      await this.getchild2()
    },
    methods: {
      inputFile (val) {
        this.pushOrder = val
      },
      async tsDetail () {
        const resp = await this.$axios.get('/reports/' + this.$route.query.dynamicId + '/detail')
        const openItem = resp.data.data

        this.$nextTick(() => {
          if (this.openItem.data.pushOrder) {
            this.pushOrder = this.openItem.data.pushOrder.split(',')
          }
          if (this.openItem.data.underTake) {
            this.underTake = this.openItem.data.underTake
          }
        })
        this.openItem = openItem
      },
      async getDetail () {
        const resp = await this.$axios.get('/reports/' + this.reportId + '/detail')
        console.log('resp00000', resp);
        this.parentList = resp.data.data
      },
      async getchild () {
        const paramsData = JSON.parse(JSON.stringify(this.searchParame))
        if (!this.orgQX) {
          paramsData._from = 'public'
        }
        paramsData.parentID = this.reportId
        if (this.parks) {
          paramsData.parks = this.parks
        } else {
          delete paramsData.parks
        }
        if (this.name) {
          paramsData.name = this.name
        } else {
          delete paramsData.name
        }
        if (this.createData[0]) {
          paramsData._createdFrom = this.$moment(this.createData[0]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdFrom
        }
        if (this.createData[1]) {
          paramsData._createdTo = this.$moment(this.createData[1]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdTo
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        const desserts = resp.data.data.content
        this.pageNum = resp.data.data.totalPages
        this.desserts = desserts
      },
      async getchild2 () {
        const paramsData = { formId: this.childformId, page: 0, size: 999, parentID: this.reportId }
        if (!this.orgQX) {
          paramsData._from = 'public'
        }
        if (this.parks) {
          paramsData.parks = this.parks
        } else {
          delete paramsData.parks
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        const desserts2 = resp.data.data.content
        this.desserts2 = desserts2
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep .v-dialog {
  height: auto !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
::v-deep .v-dialog{
  min-width: 800px;
  max-width: 1100px;
}
.line_box {
  max-height: 400px;
  overflow: auto;
}
</style>
